import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import { format } from "date-fns";
import { Waypoint } from "react-waypoint";
import Marquee from "react-fast-marquee";
import { useTranslation, Trans } from "react-i18next";
import { Image } from "../components/image";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { ScrollAndShow } from "../components/scroll-and-show";
import { Layout } from "../components/layout";
import { useHeader } from "../hooks/useHeader";
import { useNews } from "../hooks/useNews";
import { SEO } from "../components/seo";

import { app } from "../lib/firebaseClient";
import { getFirestore, doc, getDoc } from "firebase/firestore/lite";

export const db = getFirestore(app);

type Hour = {
	rubiaBrunch: string;
	rubiaDinner: string;
	tahona: string;
};

const IndexPage = ({ data }) => {
	const [t, i18n] = useTranslation();
	const { height, width } = useWindowDimensions();
	const { latestNews } = useNews();
	const { isFirstView, setIsFirstView } = useHeader();

	const [showLogo, setShowLogo] = useState(false);
	const [removeWhite, setRemoveWhite] = useState(false);

	useEffect(() => {
		setTimeout(() => setShowLogo(true), 500);
		setTimeout(() => setRemoveWhite(true), 2000);
	}, []);

	const [rubiaBrunch, setRubiaBrunch] = useState("");
	const [rubiaDinner, setRubiaDinner] = useState("");
	const [tahona, setTahona] = useState("");

	useEffect(() => {
		const fetchHour = async () => {
			const docRef = await getDoc(doc(db, "rubia", "hour"));
			const { rubiaBrunch, rubiaDinner, tahona } = docRef.data() as Hour;

			setRubiaBrunch(rubiaBrunch);
			setRubiaDinner(rubiaDinner);
			setTahona(tahona);
		};

		fetchHour();
	}, []);

	return (
		<Layout hideHeaderPadding isFirstView={isFirstView}>
			<SEO
				title="RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】| ファインメキシカン"
				description="メキシコ料理と日本料理を融合したモダンなフュージョンスタイルの料理とオリジナルドリンクが味わえるレストラン。コンテンポラリーなスタイルでありながら、メキシコの伝統的な調理法を取り入れ、さらに四季あふれる日本ならではの旬の食材と日本料理に宿る精神も込めた、至高のメニューをご用意しています。"
				keywords={[
					"メキシコ料理",
					"渋谷",
					"ファインメキシカン",
					"ルビア",
					"sarasa",
				]}
			/>
			<h1 className="h1">HOME | RUBIA ルビア / DJ sarasa</h1>
			<div className="top">
				<Waypoint
					onEnter={() => setIsFirstView(true)}
					onLeave={() => setIsFirstView(false)}
				>
					<div>
						<div className={`${removeWhite ? "remove" : ""} white-view`} />
						<div
							style={{ height: "100vh", width: width, overflow: "hidden" }}
							className="absolute hero-slider pc"
						>
							<Slider
								{...{
									dots: true,
									arrows: false,
									infinite: true,
									autoplay: true,
									autoplaySpeed: 3000,
									speed: 500,
									slidesToShow: 1,
									slidesToScroll: 1,
									variableWidth: true,
								}}
							>
								<img
									style={{ height: "100vh", width: width }}
									src="https://storage.googleapis.com/static.rubia.co.jp/index/mainvis1.jpg"
									alt="mainvis1"
								/>
								<img
									style={{ height: "100vh", width: width }}
									src="https://storage.googleapis.com/static.rubia.co.jp/index/mainvis2.jpg"
									alt="mainvis2"
								/>
								<img
									style={{ height: "100vh", width: width }}
									src="https://storage.googleapis.com/static.rubia.co.jp/index/mainvis3.jpg"
									alt="mainvis3"
								/>
								<img
									style={{ height: "100vh", width: width }}
									src="https://storage.googleapis.com/static.rubia.co.jp/index/mainvis4.jpg"
									alt="mainvis4"
								/>
								<img
									style={{ height: "100vh", width: width }}
									src="https://storage.googleapis.com/static.rubia.co.jp/index/mainvis5.jpg"
									alt="mainvis5"
								/>
							</Slider>
							<div className="news">
								<div className="news-text">NEWS</div>
								<div className="divider" />
								<Slider
									{...{
										dots: true,
										arrows: false,
										infinite: true,
										autoplay: true,
										autoplaySpeed: 3000,
										speed: 500,
										slidesToShow: 1,
										slidesToScroll: 1,
									}}
								>
									{latestNews.map((newsItem, index) => (
										<Link key={index} to={`/news/${newsItem.id}`}>
											<div
												key={index}
												className={`news-title font-yu ${
													i18n.language === "en" && newsItem.titleEn
														? "font-lato"
														: "font-yu"
												}`}
											>
												<div>
													{format(new Date(newsItem.date), "yyyy.MM.dd")}{" "}
													{(i18n.language === "en" && newsItem.titleEn) ||
														newsItem.title}
												</div>
											</div>
										</Link>
									))}
								</Slider>
							</div>
							<div className="scroll-arrow show font-lato">
								<div className="scroll-text">Scroll</div>
								<span></span>
							</div>
						</div>

						<div
							style={{ height: height, width: width, overflow: "hidden" }}
							className="absolute hero-slider sp"
						>
							<Slider
								{...{
									dots: false,
									arrows: false,
									infinite: true,
									autoplay: true,
									autoplaySpeed: 5000,
									speed: 500,
									slidesToShow: 1,
									slidesToScroll: 1,
									variableWidth: true,
								}}
							>
								<img
									className="slider-image"
									style={{ height: "100vh", width: width }}
									src="https://storage.googleapis.com/static.rubia.co.jp/index/mainvis1_sp.jpg"
									alt="mainvis1_sp"
								/>
								<img
									className="slider-image"
									style={{ height: "100vh", width: width }}
									src="https://storage.googleapis.com/static.rubia.co.jp/index/mainvis2_sp.jpg"
									alt="mainvis2_sp"
								/>
								<img
									className="slider-image"
									style={{ height: "100vh", width: width }}
									src="https://storage.googleapis.com/static.rubia.co.jp/index/mainvis3_sp.jpg"
									alt="mainvis3_sp"
								/>
								<img
									className="slider-image"
									style={{ height: "100vh", width: width }}
									src="https://storage.googleapis.com/static.rubia.co.jp/index/mainvis4_sp.jpg"
									alt="mainvis4_sp"
								/>
								<img
									className="slider-image"
									style={{ height: "100vh", width: width }}
									src="https://storage.googleapis.com/static.rubia.co.jp/index/mainvis5_sp.jpg"
									alt="mainvis5_sp"
								/>
							</Slider>
							<div className="news">
								<div className="news-text">News</div>
								<div className="divider" />
								<Slider
									{...{
										dots: true,
										arrows: false,
										infinite: true,
										autoplay: true,
										autoplaySpeed: 3000,
										speed: 500,
										slidesToShow: 1,
										slidesToScroll: 1,
									}}
								>
									{latestNews.map((newsItem, index) => (
										<Link key={index} to={`/news/${newsItem.id}`}>
											<div
												key={index}
												className={`news-title font-yu ${
													i18n.language === "en" && newsItem.titleEn
														? "font-lato"
														: "font-yu"
												}`}
											>
												<div>
													{format(new Date(newsItem.date), "yyyy.MM.dd")}{" "}
													{(i18n.language === "en" && newsItem.titleEn) ||
														newsItem.title}
												</div>
											</div>
										</Link>
									))}
								</Slider>
							</div>

							<div className="scroll-arrow show font-lato">
								<div className="scroll-text">Scroll</div>
								<span></span>
							</div>
						</div>

						<div
							className={`${
								showLogo ? "show" : ""
							} logo-wrapper grid place-items-center w-full h-screen`}
						>
							<Image filename="logo.jpg" alt="logo" />
							<Image filename="logo_tahona.png" alt="logo_tahona" />
						</div>

						<div className="reservation-bottons">
							<a
								className={`reservation-botton ${
									i18n.language === "ja" ? "font-yu" : "font-lato"
								}`}
								href="https://liff.line.me/1645278921-kWRPP32q?openerPlatform=native&openerKey=urlSchema%3Aexternal&accountId=417buqtc#mst_challenge=iPjSNmiI2DmPuciC0x7nQaapwdHgJZFLqdYVoddstaU"
								target="_blank"
								rel="noopener"
							>
								{i18n.language === "ja" ? (
									<>LINEで席を予約する</>
								) : (
									<>Reserve on LINE</>
								)}
							</a>

							<a
								className={`reservation-botton ${
									i18n.language === "ja" ? "font-yu" : "font-lato"
								}`}
								href="https://www.hotpepper.jp/strJ001264866/"
								target="_blank"
								rel="noopener"
							>
								{i18n.language === "ja" ? (
									<>Netで席を予約する</>
								) : (
									<>Reserve on Web</>
								)}
							</a>
						</div>
					</div>
				</Waypoint>

				<div className="restaurant">
					<ScrollAndShow>
						<div className="slider-wrapper">
							<Marquee gradient={false} speed={40}>
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/index/RESTAURANT1.jpg"
									alt="RESTAURANT1"
								/>
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/index/RESTAURANT2.jpg"
									alt="RESTAURANT2"
								/>
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/index/RESTAURANT3.jpg"
									alt="RESTAURANT3"
								/>
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/index/RESTAURANT4.jpg"
									alt="RESTAURANT4"
								/>
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/index/RESTAURANT5.jpg"
									alt="RESTAURANT5"
								/>
							</Marquee>
						</div>
					</ScrollAndShow>
					<ScrollAndShow delay={200}>
						<div className="info-wrapper font-lato">
							<div className="floor-text">1F</div>
							<div className="floor-name-text">RUBIA</div>
							<div className="floor-text">The Restaurant</div>
							<div className="hours-text">
								<div>BRUNCH {rubiaBrunch}</div>
								<div>DINNER {rubiaDinner}</div>
							</div>
							<div className="menu-links">
								<a
									href="https://storage.googleapis.com/static.rubia.co.jp/menu_pdf/1F_BRUNCH.pdf"
									target="_blank"
									rel="noopener"
								>
									BRUNCH MENU
								</a>
								<a
									href="https://storage.googleapis.com/static.rubia.co.jp/menu_pdf/1F_DINNER.pdf"
									target="_blank"
									rel="noopener"
								>
									DINNER MENU
								</a>
							</div>
						</div>
					</ScrollAndShow>
				</div>

				<div className="lounge">
					<ScrollAndShow delay={200}>
						<div className="slider-wrapper">
							<Marquee gradient={false} speed={40}>
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/index/BAR1.jpg"
									alt="BAR1"
								/>
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/index/BAR2.jpg"
									alt="BAR2"
								/>
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/index/BAR3.jpg"
									alt="BAR3"
								/>
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/index/BAR4.jpg"
									alt="BAR4"
								/>
								<img
									src="https://storage.googleapis.com/static.rubia.co.jp/index/BAR5.jpg"
									alt="BAR5"
								/>
							</Marquee>
						</div>
					</ScrollAndShow>
					<ScrollAndShow>
						<div className="info-wrapper font-lato">
							<div className="floor-text">2F</div>
							<div className="floor-name-text">
								<div>TAHONA</div>
							</div>
							<div className="floor-text">Mezcal & Tequila</div>
							<div className="floor-text">Café Bar</div>
							<div className="hours-text">
								<div>{tahona}</div>
							</div>
							<div className="menu-links">
								<a
									href="https://storage.googleapis.com/static.rubia.co.jp/menu_pdf/2F_BAR.pdf"
									target="_blank"
									rel="noopener"
								>
									MENU
								</a>
							</div>
						</div>
					</ScrollAndShow>
				</div>

				<div className="reservation">
					<ScrollAndShow>
						<div className="reserve-text font-lato">RESERVE</div>
						{i18n.language === "ja" && (
							<div className="reserve-now-text font-yu">
								今すぐ席のご予約が出来ます
							</div>
						)}
					</ScrollAndShow>
					<div
						className={`links ${
							i18n.language === "ja" ? "font-yu" : "font-lato"
						}`}
					>
						<ScrollAndShow>
							<a
								href="https://liff.line.me/1645278921-kWRPP32q?openerPlatform=native&openerKey=urlSchema%3Aexternal&accountId=417buqtc#mst_challenge=iPjSNmiI2DmPuciC0x7nQaapwdHgJZFLqdYVoddstaU"
								target="_blank"
								rel="noopener"
							>
								{i18n.language === "ja" ? (
									<>
										LINEで
										<br />
										席を予約する
									</>
								) : (
									<>Reserve on LINE</>
								)}
							</a>
						</ScrollAndShow>
						<ScrollAndShow delay={200}>
							<a
								href="https://www.hotpepper.jp/strJ001264866/"
								target="_blank"
								rel="noopener"
							>
								{i18n.language === "ja" ? (
									<>
										Netで
										<br />
										席を予約する
									</>
								) : (
									<>Reserve on Web</>
								)}
							</a>
						</ScrollAndShow>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default IndexPage;
