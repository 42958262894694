import { useState, useEffect } from 'react'

export const useWindowDimensions = () => {
	const [hasLoaded, setHasLoaded] = useState(false)
	const [screenSize, setScreenSize] = useState({
		height: 0,
		width: 0,
	})

	const updateScreenSize = () => {
		setScreenSize({ width: window.innerWidth, height: window.innerHeight })
	}

	useEffect(() => {
		if (!hasLoaded) {
			setHasLoaded(true)
			updateScreenSize()
		}
		window.addEventListener('resize', updateScreenSize)
		return () => window.removeEventListener('resize', updateScreenSize)
	}, [hasLoaded, screenSize])

	return screenSize
}
